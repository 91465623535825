import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="bg-[url('/images/bot-bg.png')] bg-cover p-[9rem] lg:mt-[-3rem]">
        
        <h2 className="text-center teamtitle text-white">
          Our Team
          </h2><br/>
          
          
          <p className="text-center teamp text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          
          <div className="flex items-center justify-center">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
        
                </div>
                </div>
    
<Container>
<div className="bg-grey-500 text-center">
  <img src="/images/Laptop.png"/>
  <h2 className="text-center teamtitle">
  Build an Automated AI Pipeline in Days Or
          </h2>
          <h2 className="text-center teamtitle lg:mt-[-14px]">
   Weeks NOT Months
          </h2>
          
         
          
          
          <p className="text-center botp">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
          
</div>

</Container>
<div className="rapw lg:p-[5rem] lg:mt-[5rem]">
<h2 className="text-center teamtitle ">
Where Can I Use RAPBot?
          </h2><br/>
          
          
          <p className="text-center lg:mt-[-1rem]">High-volume, rule-based, data-intensive processes are the golden area for RAPBot.</p>
		  <br/><p className="text-center ">Accounts Payables, Accounts Receivables, logging into applications, support tickets, payroll, </p>
          
      <p className="text-center ">generating reports and much more. Automate processes in mere minutes. Eliminate the tedium </p>
      <p className="text-center ">  and errors that typically plague them, and reap rapid ROI.</p>
</div>

<Container>
<div className="lg:mt-[5rem]">
<h2 className="text-center teamtitle">
Four Steps to Automation
          </h2>
          
          
          <p className="text-center teamp mt-[12px]">Automate in days with our methodical, process-based approach that is low code, quick win focused and rapid deployment. Target 50%+ ROI within months.</p>
          
		  
		  <img  className="text-center relative" src="/images/rpa.png"/>
		  <div className="max-w-sm rounded overflow-hidden shadow-lg lg:mt-[-24rem] lg:ml-[-1rem] lg:absolute">

  <div className="px-6 py-4 bg-white">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
    </p>
  </div>
  </div>



  <div className="max-w-sm rounded overflow-hidden shadow-lg lg:mt-[-12rem] lg:ml-[12rem] lg:absolute">
  <div className="px-6 py-4 bg-white">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
    </p>
  </div>
  </div>
  <div className="max-w-sm rounded overflow-hidden shadow-lg lg:mt-[-7rem] lg:ml-[38rem] lg:absolute">
  <div className="px-6 py-4 bg-white">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
    </p>
  </div>
  
  
</div>
<div className="max-w-sm rounded overflow-hidden shadow-lg lg:mt-[-32rem] lg:ml-[50rem] lg:">
  <div className="px-6 py-4 bg-white">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
    </p>
  </div>
  </div>
</div>




<div className="lg:mt-[29rem] ">
<h2 className="text-center teamtitle">
Why RAPFlow?
          </h2>
          
          
          <p className="text-center "> RAPFlow is the industry-leading choice for automating tedious tasks for extracting structured data
</p>
<p className="text-center ">  and orchestrating an AI pipeline of tasks with no specialist AI or data knowledge.
</p>
          <div className="grid lg:grid-cols-2 lg:gap-4 mt-[2rem]">
<div className=" bgyellow    webcard  ">
  
  <div className="flow-root">
	   <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
		   <li>
			   <div className="flex items-center space-x-4">
				   <div className="flex-shrink-0">
					   <img className="w-[6rem]  rounded-full" src="/images/Group-1.png" alt="Neil image"/>
				   </div>
				   <div className="flex-1 min-w-0 p-[1rem]">
					 <h1 className="webtitle1">Automate Any Task </h1>
					   <p className="webp g1 text-sm font-medium">
					   Mimic human action to copy and paste data, read and write to interfaces, make calculations and more. The RAPBot also performs back-end actions like calling an API or reading/writing to a database.</p>
					  
				   </div>
				  
			   </div>
		   </li>
		 
		 
		 
		 
	   </ul>
  </div>
</div>
<div className=" bgred    webcard  bggreen ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem]  rounded-full" src="/images/Group-2.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Boost Operational Efficiency</h1>
                        <p className="webp g1 text-sm font-medium ">
                        RAPBot’s automation accelerates processes, cuts costs and minimizes errors providing greater operational efficiency.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
<div className="     webcard bgviolet ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem]  rounded-full" src="/images/Group-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Rapid ROI & Low TCA</h1>
                        <p className="webp g1 text-sm font-medium ">
						Automation enables up to 70% reduction in processing costs which leads to rapid ROI. Our simple licensing model reduces total cost of automation significantly.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
<div className="     webcard bgpink ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem]  rounded-full" src="/images/Group-4.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Unlimited Scalability</h1>
                        <p className="webp g1 text-sm font-medium ">
                        The sky is the limit. Actually that’s not true, there is no limit on scaling operations with RAPBot. Deploy across platforms – desktop, cloud, server or a virtual machine.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
<div className="bgred    webcard   ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem]  rounded-full" src="/images/Group-5.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Platform Agnostic</h1>
                        <p className="webp g1 text-sm font-medium ">
                        There are no platform restrictions for RAPBot so it easily augments your existing systems and can work across platforms Windows, Linux or Mac.</p>                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
<div className=" bglblue    p-[1rem]  webcard  ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem] rounded-full" src="/images/Group-6.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Low Infrastructure Footprint</h1>
                        <p className="webp g1 text-sm font-medium ">
                        RAPBot has minimal infrastructure requirements and can work with a very low infrastructure footprint – as low as 1 CPU and 2GB of RAM.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
</div>
<div className=" bgdblue bg-white w-[39rem] ml-[21rem]   webcard  dark:bg-gray-800 dark:border-gray-700">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem]  rounded-full" src="/images/Group-7.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Inbuilt Items</h1>
                        <p className="webp g1 text-sm font-medium  ">
                        Credentialing, orchestrator, OCR all in one convenient package. Pay nothing extra for licensing, unless you wish to use our AI components.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>


  


 
          </div>
</div>
</Container>
<br/>

  
  </Layout>
)

export default IndexPage
